@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', -apple-system , BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .nav-span {
    @apply cursor-pointer text-slate-700 hover:border-b-4 border-slate-700;
  }
  .nav-span-active {
    @apply text-slate-700 p-2 border-b-4 border-slate-700;
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.fade-in.show {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .nav-bar-menu {
    display: none;
  }
  
}

@media screen and (min-width: 768px) {
  .burger-menu {
    display: none;
  }
}
